body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no--scroll {
  overflow: hidden !important;
  height: 100% !important;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

@font-face {
  font-family: 'Rubik';
  src: url(./assets/fonts/Rubik/Rubik-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url(./assets/fonts/Rubik/Rubik-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url(./assets/fonts/Rubik/Rubik-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.ant-picker-body {
  padding: 8px 11px !important;
}
